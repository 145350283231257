import req from '../../libs/axios';

export function getServices(params) {
  return req({
    url: "/api/services",
    method: 'get',
    params
  })
}

export function showServices(id) {
  return req({
    url: `/api/services/${id}`,
    method: 'get'
  })
}

export function addServices(data) {
  return req({
    url: "/api/services",
    method: 'post',
    data
  })
}

export function updateServices(data) {
  return req({
    url: `/api/services${data.id}`,
    method: 'put',
    data
  })
}

export function deleteServices(id) {
  return req({
    url: `/api/services/${id}`,
    method: 'delete'
  })
}