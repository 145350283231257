<template>
  <div>
    <b-overlay :show="loading" no-wrap spinner-variant="primary"></b-overlay>
    <b-card no-body class="card-statistics">
      <b-card-body class="statistics-body">
        <b-row>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                    size="48"
                    :variant="'primary'"
                >
                  <feather-icon
                      size="24"
                      :icon="'TrendingUpIcon'"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{$t('All')}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  <h4 class="w-100">
                    {{reportCount}}
                  </h4>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3">
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    :variant="'success'"
                >
                  <feather-icon
                      size="24"
                      :icon="'DollarSignIcon'"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{$t('Sum')}}
                </h4>
                <b-card-text class="font-small-3 mb-0" v-if="reportSum">
                  <div class="w-100" v-for="(val, index) in reportSum" :key="index">
                    {{val.totalsum | moneyDisplayFormat}} {{val.currency.name}}
                  </div>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-row class="align-items-end">
        <b-col md="3"
          v-if="checkRoleUser"
        >
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Branches')}}
            </p>
            <div class="wrapper-form">
              <v-select
                class="w-100"
                label="name"
                v-model="filter.branch_id"
                :options="branchesOptions"
                @input="getDataByFilter"
              ></v-select>
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Countries')}}
            </p>
            <div class="wrapper-form">
              <v-select
                class="w-100"
                label="name_ru"
                v-model="filter.country_id"
                :options="countryOptions"
                @input="getDataByFilter"
              ></v-select>
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.tableHeader.date')}}
            </p>
            <div class="wrapper-form">
              <input type="date" class="form-control" v-model="filter.date" @input="getDataByFilter">
            </div>
          </div>
        </b-col>

        <b-col md="2" class="ml-auto">
          <div class="d-flex justify-content-end w-100 mb-1">
            <b-button variant="primary" @click="getDataByFilter">
              {{$t('Search')}}
            </b-button>

            <b-button
              variant="success"
              :disabled="form.quantity === 0 || form.quantity === null"
              class="ml-2"
              @click="getSelecteds"
            >
              {{$t('message.Save')}}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-table
        :fields="fields"
        :items="items"
        :busy="busy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(checkbox)="{item}">
          <b-form-checkbox v-if="!item.collection_expense" :value="true" @input="checkItems" :unchecked-value="false" v-model="item.checked"></b-form-checkbox>
        </template>
      </b-table>
    </b-card>

    <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize" @input="fetchData">
    </b-pagination>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import {getServices} from '@/api/services/services';
  import {getCollectionExpances,setCollectionExpance} from '@/api/payments/collection-expance';
  import {getCountries} from '@/api/countries/countries';
  import {getBranches} from '@/api/branchs/branchs';
  import moment from 'moment'
  import {mapGetters} from 'vuex'

  export default {
    name: "Collection-Insurance",
    data() {
      return {
        fields: [
          {key: 'checkbox',label:''},
          {key: 'id', label: this.$t('message.ID')},
          {key: 'start_date', label: this.$t('StartDate')},
          {key: 'end_date', label: this.$t('EndDate')},
          {key: 'country.name_ru', label: this.$t('message.Countries')},
          {key: 'market_price', label: this.$t('message.Market_price')},
        ],
        items: [],
        id: null,
        actionModal: false,
        servicesOptions: [],
        pagination: {
          page: 1,
          pageSize: 15,
        },
        total: 1,
        form: {
          sum: null,
          clients: null,
          quantity: null,
        },
        fd: {
          id: null,
          contract_id: null,
          currency_id: null,
          market_price: null, // jami
        },
        filter: {
          branch_id: null,
          date: null,
          country_id: null,
        },
        filters: {
          branch_id: null,
          date: null,
          country_id: null
        },
        branchesOptions: [],
        countryOptions: [],
        reportSum: [],
        reportCount: 0,
        busy: true,
        loading: false,
      }
    },
    methods: {
      fetchData() {
        this.loading = true;
        getCollectionExpances({insurance: true, ...this.pagination, ...this.filters}).then(res => {
          this.items = res.data.data.data;
          this.total = res.data.data.total
          this.items.forEach(item => {
            item.checked = false;
            if(item.collection_expense){
              item._rowVariant = 'success';
            }
          })
          this.reportCount = res.data.totalCount;
          this.reportSum = res.data.totalSum;
          this.busy = false;
          this.loading = false;
        })
      },
      getServicesOptions() {
        getServices().then( res => {
          this.servicesOptions = res.data.data;
        })
      },
      checkItems() {
        this.form.quantity = this.items.filter(item => item.checked).length;
        if(this.form.quantity > 0) {
          this.form.sum = this.items.filter(item => item.checked === true).map(item => parseInt(item.market_price)).reduce((a,b) => a + b)
          this.form.clients = this.items.filter(item => item.checked).map(item => item.id)
        } else {
          this.form.sum = 0;
          this.form.clients = 0;
        }
      },
      getSelecteds() {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
          title: this.$t('areYouSure'),
          text: this.$t('data.sum') + this.form.sum + '; ' + this.$t('data.quantity') + this.form.quantity + ';',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('message.submit'),
          cancelButtonText: this.$t('message.back'),
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            const form = this.items.filter(item => item.checked).map(item => Object.assign({}, {id:item.id, market_price:item.market_price,
              currency_id: item.currency_id, service_id: item.contract.service_id, service_item_id: item.contract.service_item_id}));
            setCollectionExpance({insurances: form}).then(res => {
              swalWithBootstrapButtons.fire(
                  `${this.$t('successMessage1')}`,
                  `${this.$t('successMessage')}`,
                  'success'
              )
              this.fetchData();
            })
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              `${this.$t('message.reject')}`,
              `${this.$t('message.rejectInfo')}`,
              'error'
            )
          }
        })
      },
      getBranchesOptions() {
        getBranches().then( res => {
          this.branchesOptions = res.data.data
        })
      },
      getCountriesOptions() {
        getCountries().then( res => {
          this.countryOptions = res.data.data.data
        })
      },
      getDataByFilter() {
        this.filters.branch_id = this.filter.branch_id ? this.filter.branch_id.id : null;
        this.filters.date = this.filter.date ? moment(this.filter.date).format('YYYY-MM-DD') : null;
        this.filters.country_id = this.filter.country_id ? this.filter.country_id.id : null;
        this.fetchData();
      }
    },
    created() {
      this.fetchData();
      this.getServicesOptions();
      this.getCountriesOptions();
      this.getBranchesOptions();
    },
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if(this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'SuperAdmin')
        }
        return false
      }
    }
  }
</script>

<style lang="scss">
  .table {
    tr {
      white-space: nowrap;
    }
  }
  .wrapper {
    margin-top: 10px;

    &-title {
      margin-bottom: 3px;
    }

    &-form {
      display: flex;
      align-items: center;
      background: none;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;

      svg {
        font-size: 18px;
        margin: 0 10px;
        opacity: 0.7;
      }

      input {
        border: none;
      }

      div {
        width: 100%;
        .vs__dropdown-toggle {
          border: none !important;
        }
      }
    }
  }
</style>
