import request from '../../libs/axios'

export function getBranches(params) {
  return request({
    url: '/api/branches',
    method: 'get',
    params
  })
}

export function createBranchById(){
  return request({
    url:'/api/branches',
    method:'get'
  })
}

export function getBranchById(data){
  return request({
    url: `/api/branches/index/${data.id}`,
    method: 'get',
  })
}

export function addBranches(data){
  return request({
    url: '/api/branches',
    method: 'post',
    data,
  })
}

export function editBranches(data){
  return request({
    url: `/api/branches/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteBranches(data){
  return request({
    url: `/api/branches/${data.id}`,
    method: 'delete'
  })
}

export function getBranchesById(id) {
  return request({
    url: `/api/branches/${id}`,
    method:'get',
  })
}
