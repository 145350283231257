import request from '../../libs/axios'


export function getCollectionExpances(params) {
  return request({
    url:'/api/collection-expanse',
    method:'get',
    params,
  })
}

export function setCollectionExpance(data) {
  return request({
    url:'/api/collection-expanse',
    method:'post',
    data,
  })
}
